import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroPagesCenter from "../../components/heroes/hero-pages-center"
import GoData from "../../content/go/index.json"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImageFluid from "../../components/misc/image-fluid";
import TrialForm from "../../components/forms/trial-form"
import ClientsTicker from "../../components/misc/clients-ticker"
import TickerData from "../../content/home/ticker.json"

const GoIndexPage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale} title={GoData[intl.locale].title} description={GoData[intl.locale].text} pathname={location.pathname} />
        <HeroPagesCenter theme={`primary`} content={GoData[intl.locale]} />
        <div className={`go-app`}>
            <Container>
                <Row noGutters={true}>
                    <Col md={6}>
                        <div className={`top-small-overflow-wrapper`}>
                            <div className={`go-app-content`}>
                                <div className={`title`}>{GoData[intl.locale].content.title}</div>
                                <div className={`reasons`}>
                                    {GoData[intl.locale].content.reasons.map((reason, i) => {
                                        return (
                                            <div key={i} className={`reason`}>
                                                <FontAwesomeIcon icon="arrow-right" />
                                                {reason.text}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className={`buttons`}>
                                    <div className={`button`}><a href={`https://play.google.com/store/apps/details?id=re.notifica.go&hl=en&gl=US`} target={`_blank`} rel={`noopener`}><ImageFluid alt={`Google Play`} filename={GoData[intl.locale].content.buttons.android} /></a></div>
                                    <div className={`button`}><a href={`https://apps.apple.com/ma/app/notificare-go/id1618799139`} target={`_blank`} rel={`noopener`}><ImageFluid alt={`App Store`} filename={GoData[intl.locale].content.buttons.apple} /></a></div>
                                    {/*<div className={`button`}><a href={`https://notificare.com/go`} target={`_blank`} rel={`noopener`}><ImageFluid alt={`App Gallery`} filename={GoData[intl.locale].content.buttons.huawei} /></a></div>*/}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={`top-overflow-wrapper`}>
                            <div className={`go-app-form`}>
                                <TrialForm />
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className={`mt-4`}>
                    <ClientsTicker content={TickerData[intl.locale]} />
                </div>
            </Container>
        </div>
    </Layout>
)

export default injectIntl(GoIndexPage)
